.ant-modal {
  width: min-content !important;
  .ant-modal-content {
    background-color: rgba(255, 255, 255, 0.95);
  }
}

.ant-modal-close {
  z-index: 30;
}

.ant-modal-close:hover {
  border: 0px;
}

.ant-picker-input input {
  font-size: 11px;
}

.ant-modal-title {
  text-align: center;
}

.pending-order-modal {
  .ant-modal-content {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
  }
  .ant-modal-header {
    border-radius: 10px;
    border: 0;
    padding-bottom: 0;
  }
  .ant-modal-title {
    padding-top: 40px;
    color: $primary;
    font-weight: 700;
    font-size: 14px;
  }
  .modal-icon {
    color: #ffffff;
    font-size: 24px;
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $primary;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-footer {
    border: 0;
  }
}
